import { Button } from '@/components/shared/buttons/button';
import { ButtonGroup } from '@/components/shared/buttons/button-group';
import { Text } from '@/components/shared/element/text';
import { Image } from '@/components/shared/media/image';
import { youtubeURL } from '@/components/shared/utility/format';
import { styled } from '@/stitches.config';
import { IItem, IMy24Theme, IMy24Tracking } from '@/types/shared';

const StyledContent = styled('div', {
  width: '100%',
  fontSize: 0,
  variants: {
    align: {
      left: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
      right: {
        textAlign: 'right',
      },
    },
  },
  defaultVariants: {
    align: 'center',
  },
});

const imagePortrait = {
  aspectRatio: 'var(--splash-logo-m-width) / var(--splash-logo-m-height)',
  width:
    'clamp(calc(var(--splash-logo-m-width) * 1px), calc(var(--splash-logo-m-width) / (var(--splash-mobile-width) - (var(--splash-mobile-gap-x) * 2)) * 100%), calc(var(--splash-logo-w-width) * 1px))',
};
const SplashLogoBottom = styled('div', {
  position: 'relative',
  mx: 'auto',
  '@lg': {
    aspectRatio: 'var(--splash-logo-w-width) / var(--splash-logo-w-height)',
    width:
      'calc(var(--splash-logo-w-width) / (var(--splash-desktop-width) - (var(--splash-desktop-gap-x) * 2)) * 100%)',
    '@portrait': {
      ...imagePortrait,
    },
  },
  '@maxlg': {
    '@portrait': {
      ...imagePortrait,
    },
    '@landscape': {
      aspectRatio: 'var(--splash-logo-w-width) / var(--splash-logo-w-height)',
      width:
        'clamp(calc(var(--splash-logo-m-width) * 1px), calc(var(--splash-logo-w-width) / (var(--splash-desktop-width) - (var(--splash-desktop-gap-x) * 2)) * 100%), calc(var(--splash-logo-w-width) * 1px))',
    },
  },
  '@media (max-width: 359px) and (orientation: portrait)': {
    ...imagePortrait,
    width: '100%',
  },
  '& > *': {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    '& > *': {
      position: 'absolute !important',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      '& > *': {
        '@lg': {
          objectPosition: '0 0 !important',
        },
      },
    },
  },
});

interface IProps extends React.ComponentProps<typeof StyledContent> {
  css?: any;
  theme?: IMy24Theme;
  className?: string;
  headline: IItem;
  dataTest?: string;
  dataTrack?: IMy24Tracking;
  onClick?: () => void;
  splash?: boolean;
}

export const ContentWithAction: React.FC<IProps> = ({
  theme = 'dark',
  splash = false,
  ...props
}) => {
  const options = {
    align: props.align,
  };
  return (
    <StyledContent css={props?.css} className={props?.className} {...options}>
      {!!props?.headline?.preTitle && (
        <Text
          as="h2"
          size="text-h3"
          variant={theme === 'light' ? 'text-gray-darkest' : 'text-white'}
          wrap
          dangerouslySetInnerHTML={{
            __html: props?.headline?.preTitle,
          }}
          data-test={props?.dataTest + '_preTitle'}
          css={{ marginBottom: '$space-1' }}
        />
      )}
      {!!props?.headline?.title && (
        <Text
          as="h3"
          size="text-h2"
          variant={theme === 'light' ? 'text-gray-darkest' : 'text-white'}
          wrap
          dangerouslySetInnerHTML={{
            __html: props?.headline?.title,
          }}
          data-test={props?.dataTest + '_title'}
        />
      )}
      {!!splash &&
        !!props?.headline?.image &&
        !!props?.headline?.imageDesktop && (
          <SplashLogoBottom
            css={{
              '--splash-logo-m-width': props?.headline?.image?.width,
              '--splash-logo-m-height': props?.headline?.image?.height,
              '--splash-logo-w-width': props?.headline?.imageDesktop?.width,
              '--splash-logo-w-height': props?.headline?.imageDesktop?.height,
            }}
          >
            <Image
              priority
              src={props?.headline?.image.src}
              width={props?.headline?.image.width}
              height={props?.headline?.image.height}
              alt={props?.headline?.image.alt}
              layout="fill"
              objectFit="contain"
              sources={
                props?.headline?.image?.src.includes('.svg')
                  ? undefined
                  : [
                      {
                        srcset: props?.headline?.image?.src,
                        media: { minWidth: 640 },
                        options: { width: props?.headline?.imageDesktop.width },
                      },
                      {
                        srcset: props?.headline?.image?.src,
                        media: { maxWidth: 639 },
                        options: { width: props?.headline?.image.width },
                      },
                    ]
              }
              useSrcSetPattern={props?.headline?.image?.src.includes('.svg')}
            />
          </SplashLogoBottom>
        )}
      {!!props?.headline?.youtube && (
        <Button
          label={props?.headline?.youtube?.label}
          icon={props?.headline?.youtube?.icon}
          isTransparent
          isIconLarge
          size={props?.headline?.youtube?.size}
          onClick={props?.onClick}
          tracking={{
            dataTrack: props?.dataTrack ?? '',
            dataTrackSection: props?.dataTest,
            dataTrackText: props?.headline?.youtube?.label,
            dataTrackValue: props?.headline?.preTitle,
            dataTrackUrl: youtubeURL(props?.headline?.youtube?.url ?? ''),
          }}
          test={{
            dataTest: props?.dataTest + `_video_button`,
          }}
          css={{ marginTop: '$space-5' }}
        />
      )}
      {!!props?.headline?.buttons?.length &&
        props?.headline?.buttons?.length > 0 && (
          <ButtonGroup
            className="content-buttons"
            wrap="noWrap"
            justify="center"
            css={{
              pe: 'all',
              marginTop: '$space-6',
              '@lg': {
                marginTop: '$space-8',
              },
            }}
          >
            {props?.headline?.buttons?.map((button, i) => (
              <Button
                key={i}
                href={button?.url}
                target={button?.target}
                border={button?.border}
                label={button?.label}
                icon={button?.icon}
                variant={button?.color}
                size={button?.size}
                isMinWidth
                tracking={{
                  dataTrack: props?.dataTrack ?? '',
                  dataTrackSection: props?.dataTest,
                  dataTrackText: button?.label,
                  dataTrackUrl: button?.url,
                  dataTrackValue: 'cta-button',
                }}
                test={{
                  dataTest:
                    button?.url === undefined
                      ? `${props?.dataTest}_home_button`
                      : `${props?.dataTest}_cta_button`,
                }}
                onClick={button?.url === undefined ? props?.onClick : undefined}
              />
            ))}
          </ButtonGroup>
        )}
    </StyledContent>
  );
};

ContentWithAction.displayName = 'ContentWithAction';
